import React from "react";
import { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Button, Table } from "semantic-ui-react";
import PayPalButton from './PayPalButton';
import {Input} from 'semantic-ui-react';
import {init_datatrans} from '../../actions/payments';
import {connect} from 'react-redux';
import Lightbox from 'react-datatrans-light-box'
import { useParams } from "react-router-dom";

 

function Datatrans ({init_datatrans, payments: {loading}, auth: {user}}) {

    const {amount, transactionId} = useParams();
    const [lightbox, showLightbox] = useState(false);
    const onLoaded = () => console.log('Loaded')
    const onOpened = () => console.log('Opened')
    const onCancelled = () => showLightbox(false)
    const onError = (data) => {
        console.log('Error:', data)
        showLightbox(false)
    }


    useEffect(() => {
        if (!transactionId) {init_datatrans(amount)};
        /*
        if(!transactionId) {init_datatrans(amount); console.log("init")} else {window.Datatrans.startPayment({
            transactionId:  transactionId
            }); console.log("start datatrans")};
        
        if(!loading & transactionId) {window.Datatrans.startPayment({
            transactionId:  transactionId
            })}*/
    }, [transactionId]);

  return (<div>
    <h1>CHF {amount} auf das Guthaben von {user ? user.name : 'XXX'} laden.</h1>
    <Button onClick={() => {
        console.log("test");
        window.Datatrans.startPayment({
            transactionId:  transactionId
            })}}>Guthaben aufladen</Button>

  </div>)
}

const mapStateToProps = state => ({
    payments: state.payments,
    auth: state.auth
})

export default connect(mapStateToProps, {init_datatrans})(Datatrans);