import axios from 'axios';

import {setAlert} from './alert';

import {
    GET_POSTS,
    POST_ERROR,
    UPDATE_LIKES,
    DELETE_POST,
    ADD_POST,
    GET_POST,
    ADD_COMMENT,
    REMOVE_COMMENT,
    GET_SPOT,
    GET_SPOTS,
    SPOT_ERROR,
    DELETE_SPOT,
    ADD_SPOT,
    LAT_LNG,
    SETLAT,
    SETLNG,
    GET_SPOTS_FILTERED,
    MAKE_RESERVATION,
    RESERVATION_ERROR,
    SET_FROM_RESERVATION,
    SET_TO_RESERVATION,
    SET_TO_RESERVATION_ERROR,
    SET_FROM_RESERVATION_ERROR,
    DELETE_ERROR,
    DELETE_RESERVATION
} from './types';

// Get spots
export const getSpots = () => async dispatch => {
    try {
        const res = await axios.get('/api/spots');

        dispatch({
            type: GET_SPOTS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: SPOT_ERROR,
            payload: {msg: 'test', status: 'test'}
            //payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}

// Make reservation

export const makeReservation = (data) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.put('/api/spots/makeReservation', data, config);
        console.log("hello?")
        dispatch({
            type: MAKE_RESERVATION,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: RESERVATION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }

}
// Get spots (filtered)
export const getSpotsFiltered = (formData) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.post('/api/spots/filtered', formData, config);

        dispatch({
            type: GET_SPOTS_FILTERED,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: SPOT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}




// Archive reservation

export const deleteReservation = reservation_id => async dispatch => {
    try {
        console.log('action')
        const res = await axios.post(`/api/profile/archive/${reservation_id}`);
        console.log(res);

        dispatch({
            type: DELETE_RESERVATION
        });

        dispatch(setAlert('Reservation gelöscht', 'success'));

    } catch (err) {
        console.log(err);
        dispatch({
            type: DELETE_ERROR,
            payload: {msg: 'unknown', status: 'error'} //{msg: err.response.statusText, status: err.response.status}
        })
    }
}



// Remove spot

export const deleteSpot = spotId => async dispatch => {
    try {
        const res = await axios.post(`/api/spots/archive/${spotId}`);
        console.log(res);
        //const res = await axios.delete(`/api/spots/${spotId}`);
        //console.log(res);

        dispatch({
            type: DELETE_SPOT,
            payload: spotId
        });

        dispatch(setAlert('Spot deleted', 'success'));

    } catch (err) {
        console.log(err);
        dispatch({
            type: DELETE_ERROR,
            payload: {msg: 'unknown', status: 'error'} //{msg: err.response.statusText, status: err.response.status}
        })
    }
}

// Add spot

export const addSpot = formData => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.post(`/api/spots`, formData, config);

        dispatch({
            type: ADD_SPOT,
            payload: res.data
        });
        console.log('addSpot');
        dispatch(setAlert('Spot created', 'success'));
    } catch (err) {
        dispatch({
            type: SPOT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}

// Get spot
export const getSpot = spotId => async dispatch => {
    try {
        const res = await axios.get(`/api/spots/${spotId}`);

        dispatch({
            type: GET_SPOT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: SPOT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}

// Set lat & lng
export const setLat = (lat) => async dispatch => {
    try {
        //const res = await axios.get(`/api/spots/${postId}`);
        console.log(lat);

        dispatch({
            type: SETLAT,
            payload: lat
        });
    } catch (err) {
        dispatch({
            type: SPOT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}

export const setLng = (lng) => async dispatch => {
    try {
        //const res = await axios.get(`/api/spots/${postId}`);
        dispatch({
            type: SETLNG,
            payload: lng
        });
    } catch (err) {
        dispatch({
            type: SPOT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}

export const setFromReservation = (from) => async dispatch => {
    try {
        dispatch({
            type: SET_FROM_RESERVATION,
            payload: from
        });
    } catch (err) {
        dispatch({
            type: SET_FROM_RESERVATION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}



export const setToReservation = (to) => async dispatch => {
    try {
        dispatch({
            type: SET_TO_RESERVATION,
            payload: to
        });
    } catch (err) {
        dispatch({
            type: SET_TO_RESERVATION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}