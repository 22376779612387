import React, {Fragment, useState} from 'react';
import {Button, Card, Divider, Header, Portal, Segment} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import {deleteSpot} from '../../actions/spot';
import { Link } from 'react-router-dom';

const MyVermieten = ({profile, deleteSpot, getCurrentProfile}) => {


    const onClickEdit = () => {

    }
    console.log(profile);


    const mySpotsCard = profile.map(spot => (
        <Card><Card.Content>
            <Card.Header>{spot.comment}</Card.Header>
          <Card.Meta>{spot._id}</Card.Meta>
          <Card.Description>
            Von: {spot.from} <br />
            Bis {spot.to}.
            <h3>Reservierungen:</h3>
    {spot.isBooked ? spot.isBooked.map(reservation => (<p style={reservation.archived ? {textDecoration:'line-through'} : {display:''}}>Von: {reservation.from}<br />
    Bis: {reservation.to}
    </p>)) : <p>Keine Reservierungen</p>}

        <Divider />
    <Link to={`/vermieten/${spot._id}`}><Button data-tooltip='Bearbeiten' ><i className='fas fa-edit' /></Button></Link>
    <Button data-tooltip='Löschen' onClick={() => {spot.isBooked ? window.alert("Parkplatz kann nicht gelöscht werden, da Reservierungen bestehen. Bitte wenden Sie sich an den Support."): deleteSpot(spot._id)}}><i className='fas fa-ban' /></Button>

          </Card.Description>
            </Card.Content></Card>
    ));


    return (
        <Fragment>
                    <h2 className="my-2">Von mir angebotene Parkplätze</h2> 
    <Card.Group>{mySpotsCard}
    <Card style={{backgroundColor: 'var(--mantis)'}}><Card.Content>
            <Card.Header>Neuer Parkplatz</Card.Header>

          <Card.Description>
              Fügen Sie einen (weiteren) Parkplatz hinzu, den Sie vermieten möchten.

              <br />
              <Divider />
            <Link to='/vermieten/new'><Button>                
                <i className="fas fa-plus"></i> Jetzt Parkplatz hinzufügen</Button></Link>
          </Card.Description>
            </Card.Content></Card>
    
    </Card.Group>    
        </Fragment>
    )
}

export default connect(null, {deleteSpot})(MyVermieten);


/*
    const mySpots = profile.map(spot => (
        <table><tbody>
        <tr><td colSpan="4">Parkplatz: {spot._id} = {spot.comment}</td></tr>
    <tr key={spot._id}>
        <td>{spot.from}</td>
    <td>{spot.to}</td>
    <td>{spot.comment}</td>
    <td><Button onClick={() => {deleteSpot(spot._id)}}><i className='fas fa-ban' /></Button></td></tr>
    <tr><td>Reservierungen:</td></tr>
    <tr><td>{spot.isBooked ? spot.isBooked.map(reservation => (<p>{reservation.from}</p>)) : <p>Keine Reservierungen</p>}</td></tr>
    <tr><td><hr /></td></tr></tbody></table>
    ));
*/