import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Register from '../auth/register';
import Login from '../auth/login';
import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';
import PrivateRoute from '../routing/PrivateRoute';
import CreateProfile from '../profile-forms/CreateProfile';
import EditProfile from '../profile-forms/EditProfile';
import AddExperience from '../profile-forms/AddExperience';
import AddEducation from '../profile-forms/AddEducation';
import Profiles from '../profiles/Profiles';
import Profile from '../profile/Profile';
import Posts from '../posts/Posts';
import Post from '../post/Post';
import Vermieten from '../platform/Vermieten';
import Mieten from '../platform/Mieten';
import NotFound from '../layout/NotFound';
import Reservierungen from '../platform/Reservierungen';
import MapComponent from '../platform/MapComponent'
import Datatrans from '../platform/Datatrans';
import DatatransStatus from '../platform/DatatransStatus';
import MyProfile from '../platform/MyProfile';

const Routes = () => {
    return (
<section className="container">
<Alert />
<Switch>
  <Route exact path="/register" component={Register} />
  <Route exact path="/login" component={Login} />
  <Route exact path="/vermieten/:edit" component={Vermieten} />
  <PrivateRoute exact path="/mieten" component={Mieten} />
  <Route exact path="/reservierungen" component={Reservierungen} />
  <PrivateRoute exact path="/create-profile" component={CreateProfile} />
  <PrivateRoute exact path="/edit-profile" component={EditProfile} />
  <PrivateRoute exact path="/add-experience" component={AddExperience} />
  <PrivateRoute exact path="/add-education" component={AddEducation} />
  <Route exact path="/profiles" component={Profiles} />
  <Route exact path="/profile/:id" component={Profile} />
  <PrivateRoute exact path="/posts" component={Posts} />
  <PrivateRoute exact path="/posts/:id" component={Post} />
  <PrivateRoute exact path="/myprofile" component={MyProfile} />
  <PrivateRoute exact path="/datatrans/:amount/:transactionId" component={Datatrans} />
  <PrivateRoute exact path="/datatrans_status" component={DatatransStatus} />

<Route component={NotFound} />
</Switch>
</section>
    )
}

export default Routes;

//   <Route exact path="/mieten" component={Mieten} />
//   <PrivateRoute exact path="/dashboard" component={Dashboard} />

