import {
    INIT_DATATRANS_ERROR,
    INIT_DATATRANS,
    CHECK_STATUS_DATATRANS,
    CHECK_STATUS_DATATRANS_ERROR,
    BALANCE_DATATRANS,
    BALANCE_DATATRANS_ERROR
} from '../actions/types'

const initialState = {
    transactionId: '',
    status: '',
    balanceInCents: null
}

export default function(state = initialState, action) {
    const {type, payload} = action;
    
    switch(type) {
        case BALANCE_DATATRANS:
            return {
                ...state,
                balanceInCents: payload.balanceInCents,
                loading: false
            }

        case INIT_DATATRANS:
            return {
                ...state,
                transactionId: payload.transactionId,
                loading: false
            }

        case CHECK_STATUS_DATATRANS:
            return {
                ...state,
                status: payload,
                loading: false
            }

        case BALANCE_DATATRANS:
        case CHECK_STATUS_DATATRANS_ERROR:
        case INIT_DATATRANS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
            
        default:
            return state;
    }
}   