import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {addSpot, setLng, setLat, getSpot} from '../../actions/spot';
import {Table, Segment, Checkbox, Button, Input, Label} from 'semantic-ui-react';
import MapComponent from './MapComponent';
import Recurring from './Recurring';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CurrencyInput from './CurrencyInput';



const Vermieten = ({addSpot, setLng, setLat, spot, profile: {profile, loading}, getSpot}) => {
  
  const {edit} = useParams();
    

  
  const [save_alert_hidden, set_save_alert_hidden] = useState(true);
  const [showMap, setShowMap] = useState(true);

  const [recurringweekly, setrecurringweekly] = useState({
    montag: {
      available: false,
      wholeday: false,
      to_time: '',
      from_time: ''
    },
    dienstag: {
      available: false,
      wholeday: false,
      to_time: '',
      from_time: ''
    },
    mittwoch: {
      available: false,
      wholeday: false,
      to_time: '',
      from_time: ''
    },
    donnerstag: {
      available: false,
      wholeday: false,
      to_time: '',
      from_time: ''
    },
    freitag: {
      available: false,
      wholeday: false,
      to_time: '',
      from_time: ''
    },
    samstag: {
      available: false,
      wholeday: false,
      to_time: '',
      from_time: ''
    },
    sonntag: {
      available: false,
      wholeday: false,
      to_time: '',
      from_time: ''
    }
  })

  const call_getSpot = async () =>  {
    await getSpot(edit)
  }

  if (edit !== 'new' & spot.spot_before_editing == null) {
    call_getSpot(edit);
    spot.spot_before_editing && setcomment(spot.spot_before_editing.comment);
    spot.spot_before_editing && setrecurringweekly({
      montag: {
        available: spot.spot_before_editing.monday_available,
        wholeday: spot.spot_before_editing.monday_wholeday,
        to_time: spot.spot_before_editing.monday_to_hour.toString() + ':' + spot.spot_before_editing.monday_to_minute.toString(),
        from_time: ''
      },
      dienstag: {
        available: false,
        wholeday: false,
        to_time: '',
        from_time: ''
      },
      mittwoch: {
        available: false,
        wholeday: false,
        to_time: '',
        from_time: ''
      },
      donnerstag: {
        available: false,
        wholeday: false,
        to_time: '',
        from_time: ''
      },
      freitag: {
        available: false,
        wholeday: false,
        to_time: '',
        from_time: ''
      },
      samstag: {
        available: false,
        wholeday: false,
        to_time: '',
        from_time: ''
      },
      sonntag: {
        available: false,
        wholeday: false,
        to_time: '',
        from_time: ''
      }
    })
  }



  useEffect(() => {
    setlat_state(spot.lat);
    setlng_state(spot.lng);
    console.log(recurringweekly);
    if (profile) {
      profile.my_spots[0] ? (setShowMap(false)) : (setShowMap(true));
    }
}, [spot.lat, spot.lng, recurringweekly, profile]);


  const [quick_hours, setQuickHours] = useState();
  const [quick_whole_day, setQuickWholeDay] = useState(false);

  const [text, setText] = useState('');

  const now = new Date;
  const inOneHour = now + 360000000
  
  const [unit, setunit ] = useState('') ;
  const [isBooked, setisBooked ] = useState(false) ;
  const [lat_state, setlat_state ] = useState(null);
  const [lng_state, setlng_state ] = useState(null) ;
  const [to_time, setto_time ] = useState(moment().add(1, 'hour').format('HH:mm')) ;
  const [from_time, setfrom_time ] = useState(moment().format('HH:mm')) ;
  const [to_date, setto_date ] = useState(moment().format('YYYY-MM-DD')) ;
  const [from_date, setfrom_date ] = useState(moment().format('YYYY-MM-DD'));
  const [recurring, setrecurring ] = useState(false) ;
  const [comment, setcomment ] = useState('') ;
  const [title, settitle ] = useState('') ;
  const [price, setprice ] = useState('') ;
  const [obfuscate, setobfuscate ] = useState(false) ;
  const [minimum_time, setminimum_time ] = useState('') ;
  const [number_of_spots, setnumber_of_spots ] = useState('') ;
  const [type, settype ] = useState('normal') ;
  const [confirmation_required, setconfirmation_required ] = useState(false) ;
  const [confirmation_requested, setconfirmation_requested ] = useState(false) ;
  const [confirmation_given, setconfirmation_given ] = useState(false) ;
  const [confirmation_declined, setconfirmation_declined ] = useState(false) ;
  const [EV_charger, setEV_charger ] = useState(false) ;

  const defaultChip = 'btn btn-light chip';
  const selectedChip = 'btn btn-primary chip';
  const [spotSizeStandard, setSpotSizeStandard] = useState(selectedChip);
  const [spotSizeMotorrad, setSpotSizeMotorrad ] = useState(defaultChip);
  const [spotSizeGross, setSpotSizeGross] = useState(defaultChip);




  const type_options = [
    { key: 'normal', text: "Standard", value: "monat"},
    { key: 'motorcycle', text: "Motorrad", value: "stunde"},
    { key: 'extra-large', text: "Extra-gross", value: "tag"}
  ];


const selectSpotSize =  (size) => {
  //e.preventDefault();
  const secondary=defaultChip;
  const primary=selectedChip;
  console.log('hallo')

  if (size == 'Standard') {
    settype('normal');
    setSpotSizeStandard(primary);
    setSpotSizeMotorrad(secondary);
    setSpotSizeGross(secondary);
  }
  else if (size == 'Motorrad') {
    settype('motorcycle');
    setSpotSizeStandard(secondary);
    setSpotSizeMotorrad(primary);
    setSpotSizeGross(secondary);
  }
  else if (size == 'Extra-gross') {
    settype('extra-large');
    setSpotSizeStandard(secondary);
    setSpotSizeMotorrad(secondary);
    setSpotSizeGross(primary);
  }



}

  const onClickDetailed = async (e) => {
    //e.preventDefault();
    await setlat_state(spot.lat);
    await setlng_state(spot.lng);
    const from = Date.parse(from_date + 'T' + from_time + '+0100');
    const to = Date.parse(to_date + 'T' + to_time + '+0100');
    
      const lat = lat_state; const lng = lng_state;
      const lat_obf = !obfuscate ? lat : (lat + Math.random()/1000);
      const lng_obf = !obfuscate ? lng : (lng + Math.random()/1000);
      console.log(from, to, lat_state, lng_state, lat_obf, lng_obf);
      if (lat_state && lng_state) {addSpot({from, 
        to,
        lat,
        lng,
        lat_obf,
        lng_obf,
        price,
        recurringweekly,
        unit,
        isBooked,
        type,
        EV_charger,
        comment,
        minimum_time,
        confirmation_required,
        confirmation_requested,
        confirmation_given,
        confirmation_declined,
        number_of_spots});
      setText('');}
  }

  const onClickSave = () => {
    set_save_alert_hidden(!save_alert_hidden);
  }

  
    
  return (<Fragment>
<h1 className='custom-font'>Verdienen Sie Geld mit Ihrem Parkplatz!</h1><br />
<div className='infobox'>
<h3 className='custom-font infobox'>
{edit !== 'new' ? (<p>Parkplatz {edit} - {comment} bearbeiten</p>) : (<p>Registrieren Sie Ihren Parkplatz hier. Einfach Adresse eingeben, Parkplatz anklicken und Details eingeben (wahlweise express oder benutzerdefiniert)<br /></p>)}
</h3></div>


  <Segment color='blue'>
<Table style={{border: 0}} stackable padded>
<Table.Row>
  <Table.Cell>
    <MapComponent  lat={lat_state} lng={lng_state} setLat={setLat} setLng={setLng} spot={spot}/></Table.Cell></Table.Row>
  </Table>
  
</Segment>


<Segment color='orange'>
<h2 className='custom-font'>Expressvermietung</h2>
<Table style={{border: 0}} stackable padded>  
  <Table.Row>
        <Table.Cell width='2' verticalAlign='top'>
            <Input placeholder="Anzahl Stunden" disabled={quick_whole_day} className="ui text" type="number" id="quick_hours" name="quick_hours" value={quick_hours} onChange={event => setQuickHours(event.target.value)}/>                  
            <br /> <br />
            <Checkbox toggle label='Ganzer Tag (von jetzt bis Mitternacht)' id="quick_whole_day" name="quick_whole_day" checked={quick_whole_day} onChange={event => {
            setQuickWholeDay(!quick_whole_day);}}/></Table.Cell>  
        <Table.Cell verticalAlign='top' width='8'><button className='btn btn-primary'>Meinen Parkplatz zur Vermietung freigeben</button></Table.Cell>
          
    </Table.Row>
  </Table>
</Segment>

<Segment color='green'>

  <h2 className='custom-font'>Benutzerdefinierte Vermietung</h2>



<Table stackable padded>
      <Table.Body>

        
      <Table.Row>
          <Table.Cell  >
          <Label pointing='right'>Von:</Label>
              <input className="ui date" type="date" id="from_date" value={from_date} onChange={event => {
                  setfrom_date(event.target.value); 
                  setto_date(event.target.value); 
                  console.log("From date", event.target.value)
                  }
                } name="from_date" />
              <input className="ui time" type="time" id="from_time" value={from_time} onChange={event => {setfrom_time(event.target.value); setto_time(event.target.value); console.log(event.target.value)}} name="from_time" /> <br />
                  </Table.Cell>
          <Table.Cell>
<Label pointing='right'>Bis:</Label>
              <input className="ui date" type="date" id="to_date" value={to_date} onChange={
                event => {
                  setto_date(event.target.value);
                  }
                } 
                name="to_date" />
              <input className="ui time" type="time" id="to_time" value={to_time} onChange={
                event => {
                  const toParsed = Date.parse(to_date + ' ' + event.target.value);
                  const fromParsed = Date.parse(from_date + ' ' + from_time)
                  console.log(toParsed, fromParsed);
                  if (from_date == to_date) {
                    moment(toParsed).isAfter(fromParsed) ? 
                    setto_time(event.target.value) : setto_time(from_time)
                }
                  else {
                    setto_time(event.target.value);
                  };

              }}
                name="to_time" /> <br />
                  </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colspan='2'>
          <Checkbox 
                  toggle 
                  label='Wiederkehrend' 
                  id="wiederholung" 
                  name="wiederholung" 
                  checked={recurring} 
                  onChange={event => {
                  setrecurring(!recurring);}}
                  /> 
            {recurring ? <Fragment><br />
          <Recurring 
                  recurringweekly={recurringweekly} 
                  setrecurringweekly={setrecurringweekly}/>
                </Fragment>: null}

        </Table.Cell>
      </Table.Row>
      <Table.Row>
          <Table.Cell>
          <Input  
                  placeholder="Preis in CHF pro Stunde" 
                  label={{ basic: true, content: 'CHF / Stunde' }}
                  labelPosition='right'
                  type="number" 
                  id="price" 
                  name="price" 
                  min="1"
                  value={price} 
                  pattern="[0-9]{1,2}\.[0-9]{2}"
                  onChange={event => {
                    //if (event.target.value >= 0) {
                      //    console.log(parseFloat(event.target.value))
                          setprice(parseInt(event.target.value))
                       // }
                      }
                    }/>                  
          </Table.Cell>
          <Table.Cell>
          

<button className={spotSizeStandard} onClick={() => selectSpotSize('Standard')}>Standard</button>
<button className={spotSizeMotorrad} onClick={() => selectSpotSize('Motorrad')}>Motorrad</button>
<button className={spotSizeGross} onClick={() => selectSpotSize('Extra-gross')}>Extra-gross</button>



                  </Table.Cell>
      </Table.Row>
      <Table.Row>
          <Table.Cell>
              
<Input fluid placeholder="Name (z.B. Adresse oder Lagebeschreibung)" className="ui text" type="text" id="title" name="title" value={title} onChange={event => settitle(event.target.value)}/> <br />                
<Input fluid placeholder="Zusätzliche Infos" className="ui text" type="text" id="comment" name="comment" value={comment} onChange={event => setcomment(event.target.value)}/>

          </Table.Cell>
          <Table.Cell verticalAlign = 'top'>
    <Checkbox toggle label='Exakten Standort verbergen' id="obfuscate" name="obfuscate" checked={obfuscate} onChange={event => {
    setobfuscate(!obfuscate);}}
    /><br/> <br/>
    <Checkbox toggle label='Ladestation für E-Fahrzeuge' id="ev" name="ev" checked={EV_charger} onChange={event => {
    setEV_charger(!EV_charger);}}
    /><br/>


          </Table.Cell>
      </Table.Row>
      
      </Table.Body>
</Table>

<button className="btn btn-primary" onClick={() => {onClickDetailed()}}>Meinen Parkplatz zur Vermietung freigeben</button>


</Segment>
      </Fragment>
    )
}

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  spot: state.spot
})

Vermieten.propTypes = {
    addSpot: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {addSpot, setLat, setLng, getSpot})(Vermieten)


/* 

<Input placeholder="Mindestmietdauer" className="ui text" type="text" id="minimum_time" name="minimum_time" value={minimum_time} onChange={event => setminimum_time(event.target.value)}/>
<Input placeholder="Anzahl Parkplätze" className="ui text" type="text" id="number_of_spots" name="number_of_spots" value={number_of_spots} onChange={event => setnumber_of_spots(event.target.value)}/><br />
<Checkbox toggle label='Nur überprüfte MieterInnen' id="confirmed_only" name="confirmed_only" checked={confirmed_only} onChange={event => {
    setconfirmed_only(!confirmed_only);}}
    /><br/>


    <Dropdown placeholder='Freier Zugang' selection options={access_options} id="access" name="access" value={access} onChange={(event, result) => {
                  const {name, value} = result;
                  setaccess(value);
                  }}/><br />

  <br/><Dropdown placeholder='Bitte auswählen' inline required options={frequency_options} id="frequency" name="frequency" value={frequency} onChange={(event, result) => {
          const {name, value} = result;
          setfrequency(value);
          console.log(frequency)
          }}/> <br/> 

          <Dropdown placeholder='Zeitraum' selection options={zeitraum_options} id="zeitraum" name="zeitraum" value={zeitraum} onChange={(event, result) => {
      const {name, value} = result;
      setzeitraum(value);
      }}/>


        const onMarkerClick = (props, marker, e) =>
    {setselectedPlace(props);
    setactiveMarker(marker);
    setshowingInfoWindow(true);};
 

  const [showingInfoWindow, setshowingInfoWindow] = useState(false);
  const [activeMarker, setactiveMarker ] = useState({});
  const [selectedPlace, setselectedPlace ] = useState({});
  const [zeitraum, setzeitraum ] = useState('stunde') ;
  const [frequency, setfrequency ] = useState('none');
  const [access, setaccess ] = useState('') ;
  const [confirmed_only, setconfirmed_only ] = useState(false) ;



  const access_options = [
    { key: 'free_access', text: "Freier Zugang", value: "free_access"},
    { key: 'restricted_access', text: "Kein freier Zugang", value: "restricted_access"}
  ];




  const zeitraum_options = [
    { key: 'monat', text: "Monat", value: "monat"},
    { key: 'stunde', text: "Stunde", value: "stunde"},
    { key: 'tag', text: "Tag", value: "tag"}
  ];

  const frequency_options = [
    { key: 'täglich', text: 'täglich', value: 'täglich'},
    { key: 'wöchentlich', text: 'wöchentlich', value: 'wöchentlich'},
    { key: 'monatlich', text: 'monatlich', value: 'monatlich'}
  ];



  const yesno = [
    {
      key: 'Ja',
      text: "Ja",
      value: true
    },
    {
      key: 'Nein',
      text: "Nein",
      value: false
    }
  ];


  import { componentDidMount } from 'react-google-maps/lib/utils/MapChildHelper';


  import MyVermieten from './ReservierungenVermieten';
import { render } from 'react-dom';
import spot from '../../reducers/spot';
import { LAT_LNG } from '../../actions/types';

<Dropdown placeholder='Parkplatztyp' selection options={type_options} id="type" name="type" value={type} onChange={(event, result) => {
const {name, value} = result;
settype(value);
}}/>

*/