import React, {useEffect, useState} from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import { Button, Checkbox, Container, Divider, Label, Segment, Table } from "semantic-ui-react";


const Wochentag = params => {

const spot = params.spot;
const day = params.day;

let from_time = null;
let to_time = null;

let dayselected = null;
let wholeday = null; 

switch (day) {
    case 'Montag':
        dayselected = spot.monday_available;
        wholeday = spot.monday_wholeday;
        if (!wholeday) {    
        to_time = spot.monday_to_hour.$numberDecimal + ':' + spot.monday_to_minute.$numberDecimal;
        from_time = spot.monday_from_hour.$numberDecimal + ':' + spot.monday_from_minute.$numberDecimal;}
        break;

    case 'Dienstag':
        dayselected = spot.tuesday_available;
        wholeday = spot.tuesday_wholeday;
        if (!wholeday) { 
        to_time = spot.tuesday_to_hour.$numberDecimal + ':' + spot.tuesday_to_minute.$numberDecimal;
        from_time = spot.tuesday_from_hour.$numberDecimal + ':' + spot.tuesday_from_minute.$numberDecimal;}
        break;

    case 'Mittwoch':
        dayselected = spot.wednesday_available;
        wholeday = spot.wednesday_wholeday;
        if (!wholeday) { 
        to_time = spot.wednesday_to_hour.$numberDecimal + ':' + spot.wednesday_to_minute.$numberDecimal;
        from_time = spot.wednesday_from_hour.$numberDecimal + ':' + spot.wednesday_from_minute.$numberDecimal;}

        break;

    case 'Donnerstag':
        dayselected = spot.thursday_available;
        wholeday = spot.thursday_wholeday;
        if (!wholeday) { 
        to_time = spot.thursday_to_hour.$numberDecimal + ':' + spot.thursday_to_minute.$numberDecimal;
        from_time = spot.thursday_from_hour.$numberDecimal + ':' + spot.thursday_from_minute.$numberDecimal;}

        break;

    case 'Freitag':
        dayselected = spot.friday_available;
        wholeday = spot.friday_wholeday;
        if (!wholeday) { 
        to_time = spot.friday_to_hour.$numberDecimal + ':' + spot.friday_to_minute.$numberDecimal;
        from_time = spot.friday_from_hour.$numberDecimal + ':' + spot.friday_from_minute.$numberDecimal;}

        break;

    case 'Samstag':
        dayselected = spot.saturday_available;
        wholeday = spot.saturday_wholeday;
        if (!wholeday) { 
        to_time = spot.saturday_to_hour.$numberDecimal + ':' + spot.saturday_to_minute.$numberDecimal;
        from_time = spot.saturday_from_hour.$numberDecimal + ':' + spot.saturday_from_minute.$numberDecimal;}

        break;

    case 'Sonntag':
        dayselected = spot.sunday_available;
        wholeday = spot.sunday_wholeday;
        if (!wholeday) { 
        to_time = spot.sunday_to_hour.$numberDecimal + ':' + spot.sunday_to_minute.$numberDecimal;
        from_time = spot.sunday_from_hour.$numberDecimal + ':' + spot.sunday_from_minute.$numberDecimal;}

        break;

    default:
        console.log('error');
        break;
}



return (<Table.Cell width='1' verticalAlign='top' textAlign='center'>
        
                        <Button toggle active={dayselected}>{day.slice(0,2)}</Button>
                         <Segment hidden={!dayselected} style={{border: 0, padding: 0}}>
                        {wholeday && (<p> ganztags</p>)}
                        <p  style={{fontSize: '10px'}}></p>
                        
                            <p> </p>
                            <Table stackable compact style={{border: 0}} hidden={wholeday}>
                                <Table.Row >
                                <Label pointing='right'>Von:</Label>{+from_time.split(':')[1] < 10 ? from_time.split(':')[0] + ':0' + from_time.split(':')[1] : from_time}
                                </Table.Row>
                                <Table.Row>
                                    <br />
                                <Label pointing='right'>Bis:</Label>{+to_time.split(':')[1] < 10 ? to_time.split(':')[0] + ':0' +  to_time.split(':')[1] : to_time}
                                </Table.Row>
                            </Table>
                            

                        </Segment>
                        </Table.Cell>
    )
}

export default Wochentag;