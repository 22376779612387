import React from "react";
import { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Button, Table } from "semantic-ui-react";
import PayPalButton from './PayPalButton';
import {Input} from 'semantic-ui-react';
import {check_status} from '../../actions/payments';
import {connect} from 'react-redux';
import Lightbox from 'react-datatrans-light-box'
import { useParams } from "react-router-dom";

 

function Datatrans ({check_status, payments: {transactionId, loading, status}, auth: {user}}) {


    useEffect(() => {
        check_status(transactionId);
        
    }, [status]);

  return (<div>
    <h1> {status ? ("Ihre Transaktion ist " + status.status) : null}. Ihr neues Guthaben ist: ...</h1>

  </div>)
}

const mapStateToProps = state => ({
    payments: state.payments,
    auth: state.auth
})

export default connect(mapStateToProps, {check_status})(Datatrans);