import {
    GET_SPOTS,
    SPOT_ERROR,
    UPDATE_LIKES,
    DELETE_SPOT,
    ADD_SPOT,
    GET_SPOT,
    ADD_COMMENT,
    REMOVE_COMMENT,
    LAT_LNG,
    SETLAT,
    SETLNG,
    GET_SPOTS_FILTERED,
    RESERVATION_ERROR,
    MAKE_RESERVATION,
    SET_FROM_RESERVATION,
    SET_TO_RESERVATION, DELETE_RESERVATION
} from '../actions/types'

const initialState = {
    spots: [],
    spot: null,
    loading: true,
    error: {},
    lat: 'lat',
    lng: 'lng',
    from_reservation: {},
    to_reservation: {},
    spot_before_editing: null
}

export default function(state = initialState, action) {
    const {type, payload} = action;
    
    switch(type) {
        case GET_SPOTS:
            return {
                ...state,
                spots: payload,
                loading: false
            }

        case GET_SPOTS_FILTERED:
            return {
                ...state,
                spots: payload,
                loading: false
            }

        case GET_SPOT:
            return {
                ...state,
                spot_before_editing: payload,
                loading: false
            }

        case SETLAT:
            return {
                ...state,
                lat: payload
                            }

        case SETLNG:
            return {
                ...state,
                lng: payload
                            }

        case SET_FROM_RESERVATION:
            return {
                ...state,
                from_reservation: payload
            }

        case SET_TO_RESERVATION:
            return {
                ...state,
                to_reservation: payload
            }

        case LAT_LNG:
            return {
                ...state,
                lat: payload.lat,
                lng: payload.lng
            }

        case ADD_SPOT:
            return {
                ...state,
                spots: [payload, ...state.spots],
                loading: false
            }

            case DELETE_RESERVATION:
            case DELETE_SPOT:
                return {
                    ...state //,
                    //profile: state.profile.profile.filter(element => element._id !== payload)
                    //spots: state.spots.filter(spot => spot._id !== payload)
                }
            case SPOT_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                }

            case MAKE_RESERVATION:
                return {
                    ...state,
                    loading: false
                }
            case RESERVATION_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                }
                
            default:
                return state;
    }
}   