import React, {useState, useRef, useEffect, Component, Fragment} from 'react';
import {getSpots, setLat, setLng, setToReservation, makeReservation} from '../../actions/spot';
import {connect} from 'react-redux';
import spot from '../../reducers/spot';
import ReactDOM from 'react-dom';

import { GoogleMap, LoadScript, Marker, InfoWindow, StandaloneSearchBox, Autocomplete } from '@react-google-maps/api';
import { Button } from 'semantic-ui-react';
import Recurring from './RecurringMieten';

 
/*global google */

const containerStyle = {
  width: '100%',
  height: '400px'
};
 

const MapComponentMieten = ({makeReservation, spots, from_reservation, to_reservation, tenant_integer}, props) => {
  
const [activeMarker, setactiveMarker] = useState({});

    
const [placeId_state, setplaceId_state] = useState(null);


const [map, setMap] = useState(null)
const [showingInfoWindow, setshowingInfoWindow] = useState(false);
const [selectedPlace, setselectedPlace] = useState({});
const [lat_place, setLat_place] = useState(47.36969051897523);
const [lng_place, setLng_place] = useState(8.539005558524545);
const [center, setCenter] = useState({
    lat: lat_place,
    lng: lng_place
  });
const [zoomlevel, setZoomlevel] = useState(18);
const [mapTypeId, setMapTypeId] = useState();


useEffect( () => {

  if (!!navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => console.log(err),
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 },
      );
    } else {
      //  // No Support Web
      alert('El navegador no soporta la geolocalización,')
    }
  })
    

const onInfoWindowOpen = (props, e, placeId) => {
    console.log("onInfoWinowOpen", placeId);
    setplaceId_state(placeId);
    const button = (<Button id={placeId} onClick={(props, e) => {
        console.log(showingInfoWindow);
        const spot_id = placeId_state ? placeId_state.toString() : '';
        const from = from_reservation.toString();
        const to = to_reservation.toString();
        const tenant = tenant_integer ? tenant_integer.toString() : '';
        makeReservation({spot_id, from, to, tenant});
        console.log(placeId_state, from_reservation, to_reservation, tenant);}}>Reservieren</Button>);
    ReactDOM.render(React.Children.only(button), document.getElementById("iwc"));
    }
    
const onMarkerClick = (props, marker, e) => {
    console.log(onMarkerClick);
    console.log(props, marker);
    setselectedPlace(props);
    setactiveMarker(marker);
    //setshowingInfoWindow(true)
    console.log("name", props, marker);
} 


const onMapClicked = (e) => {
    if (showingInfoWindow) {
    setshowingInfoWindow(false);
    setactiveMarker(null);
    }
    console.log(from_reservation, to_reservation)
};

const ConvDateFromDB = (dateFromDB) => {
    var monthNames = [
        "Januar", "Februar", "März",
        "April", "Mai", "Juni", "Juli",
        "August", "September", "Oktober",
        "November", "December"
        ];
    
    var date = new Date(dateFromDB); 
    //console.log(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var day = date.getDate();
    var month = monthNames[date.getMonth()];
    var year = date.getFullYear();
    var dateString = day + ". " + month + " " + year + ", " + hours + ":" + (minutes < 10 ? '0' + parseInt(minutes) : minutes);
    return dateString
  }



const onLoad = React.useCallback(function callback(map) {

map.setOptions({
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_BOTTOM
    },
    streetViewControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM
    },
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM
    }
  });
  

}, [])

const onUnmount = React.useCallback(function callback(map) {
  setMap(null)
}, [])

var autocomplete_const = null;

const onLoadAutocomplete = (autocomplete) => {
  autocomplete_const = autocomplete;
  autocomplete_const.addListener('place_changed', onPlaceChangedAutocomplete)

}

const onPlaceChangedAutocomplete = async () => {
  if (autocomplete_const !== null) {
    const places = await autocomplete_const.getPlace().geometry.location
    let lat_place = parseFloat(places.lat());
    let lng_place = parseFloat(places.lng());
    setCenter({lat: lat_place, lng: lng_place});
    setLat(lat_place);
    setLng(lng_place);
    setZoomlevel(20);
    setshowingInfoWindow(false);
    setactiveMarker(null);
  } else {
    console.log('Autocomplete is not loaded yet!')
  }
}


  return (
    <LoadScript
    id="script-loader"
      googleMapsApiKey="AIzaSyBHvHsNPKLxE5CQoiPgCiBUGLPzuqnwrI0"
      libraries={["places"]}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}      
        zoom={zoomlevel}
        
        onLoad={onLoad}

        onUnmount={onUnmount}
        onClick={(e) => onMapClicked(e)}
        mapTypeId = {mapTypeId}
      >
        
        <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChangedAutocomplete}
          ><input
          type="text"
          placeholder="Wo soll der Parkplatz sein?"
          autocomplete="on"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `80%`,
            height: `48px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "10%",
            top: "1%",
            marginLeft: "-0px"
          }}
        /></Autocomplete>

     
     

{spots.map(spot => (
        <Marker //onClick={onMarkerClick}
            onClick={() => {
                setselectedPlace(spot)
                setshowingInfoWindow(spot._id)}}
          key={spot._id}
          id={spot._id}
          name={spot.comment}
          from={spot.from}
          to={spot.to}
          lat = {spot.lat}
          lng = {spot.lng}
          lat_obf = {spot.lat_obf ? spot.lat_obf.$numberDecimal : spot.lat.$numberDecimal}
          lng_obf = {spot.lng_obf ? spot.lng_obf.$numberDecimal : spot.lng.$numberDecimal}
          price = {spot.price.$numberDecimal}
          recurring = {spot.recurring}
          unit = {spot.unit}
          isBooked = {spot.isBooked}
          type = {spot.type}
          EV_charger = {spot.EV_charger}
          comment = {spot.comment}
          minimum_time = {spot.minimum_time.$numberDecimal}
          confirmation_required = {spot.confirmation_required}
          confirmation_requested = {spot.confirmation_requested}
          confirmation_given = {spot.confirmation_given}
          confirmation_declined = {spot.confirmation_declined}
          number_of_spots = {spot.number_of_spots.$numberDecimal}
          obfuscated = {spot.lat_obf.$numberDecimal != spot.lat.$numberDecimal}
          position={{ lat: spot.lat_obf ? +spot.lat_obf.$numberDecimal : +spot.lat.$numberDecimal, lng: spot.lng_obf ? +spot.lng_obf.$numberDecimal : +spot.lng.$numberDecimal }}
        >

        {(showingInfoWindow == spot._id) && <InfoWindow
        position = {{lat: spot.lat, lng: spot.lng}}
      >
          <div>
          <h1>{selectedPlace.comment}</h1>
        <p>Verfügbar von: {ConvDateFromDB(selectedPlace.from)} bis: {ConvDateFromDB(selectedPlace.to)}</p>
          {selectedPlace.obfuscated ? <p>Der genaue Standort wird nach der Reservierung mitgeteilt.</p> : <p></p>}
        <div id="iwc" />
        <Recurring spot={selectedPlace} />
        {(from_reservation && to_reservation) ? 
<Button id={selectedPlace._id} onClick={(props, e) => {
            console.log(showingInfoWindow);
            const spot_id = selectedPlace._id //props.id ? props.id.toString() : '';
            const from = from_reservation.toString();
            const to = to_reservation.toString();
            const tenant = tenant_integer ? tenant_integer.toString() : '';
            makeReservation({spot_id, from, to, tenant});
            console.log(props.id, from_reservation, to_reservation, tenant);
            }
            }>Reservieren</Button> : <Button>Bitte geben Sie unten die gewünschten Daten und Uhrzeiten ein</Button>}
            </div>
        </InfoWindow>}

        </Marker>
      ))}          
            
    
        
      </GoogleMap>
    </LoadScript>
  )
}

//export default connect(null, {setLat, setLng})(MapComponent);

const mapStateToProps = (state) => ({
    from_reservation: state.spot.from_reservation,
    to_reservation: state.spot.to_reservation,
    tenant_integer: state.auth.user._id
  });
  
  export default connect(mapStateToProps, {makeReservation})(MapComponentMieten);


/*



<InfoWindow
      marker={activeMarker}
      visible={showingInfoWindow}
      onOpen={props, e => {
        console.log("Props InfoWindow", props.id);
        console.log(selectedPlace.id);
        const placeId = selectedPlace.id;
        onInfoWindowOpen(props, e, placeId);
      }}
      id = {selectedPlace.id}
      from_reservation = {from_reservation}
      to_reservation = {to_reservation}
    >
        <div>
          <h1>{selectedPlace.name}</h1>
          <h2>{selectedPlace.id}</h2>
        <p>Verfügbar von: {ConvDateFromDB(selectedPlace.from)} bis: {ConvDateFromDB(selectedPlace.to)}</p>
          {selectedPlace.obfuscated ? <p>Der genaue Standort wird nach der Reservierung mitgeteilt.</p> : <p></p>}
        <div id="iwc" /></div>
        
    </InfoWindow>

<div id="iwc" />


const [lat, setlat] = useState({});
const [lng, setlng] = useState({});
const [input, setInput] = useState('');
const [test, setTest] = useState(0);


*/