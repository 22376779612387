import React, {Fragment, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getSpots, getSpotsFiltered, setFromReservation, setToReservation, makeReservation} from '../../actions/spot';
import {getCurrentProfile} from '../../actions/profile';
import Spinner from '../layout/Spinner'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Header, Container, Card, Checkbox, Button, Input, Dropdown, TextArea, Label, Segment, Table} from 'semantic-ui-react';
import MapComponentMieten from './MapComponentMieten'
import QR from './QR';


const Mieten = ({getCurrentProfile, auth: {user}, getSpots, getSpotsFiltered, spot: {spots, loading, to_reservation, from_reservation}, setFromReservation, setToReservation, makeReservation, setLat, setLng, spot}) => {
  const [text, setText] = useState('');
  const [showingInfoWindow, setshowingInfoWindow] = useState(false);
  const [activeMarker, setactiveMarker ] = useState({});
  const [selectedPlace, setselectedPlace ] = useState({});
  const [lat_state, setlat_state ] = useState(null);
  const [lng_state, setlng_state ] = useState(null) ;
  const [to_time, setto_time ] = useState('') ;
  const [from_time, setfrom_time ] = useState('') ;
  const [to_date, setto_date ] = useState('') ;
  const [from_date, setfrom_date ] = useState('') ;
  const [recurring, setrecurring ] = useState(false) ;
  const [frequency, setfrequency ] = useState('');
  const [comment, setcomment ] = useState('') ;
  const [zeitraum, setzeitraum ] = useState('stunde') ;
  const [price, setprice ] = useState('') ;
  const [access, setaccess ] = useState('') ;
  const [confirmed_only, setconfirmed_only ] = useState(false) ;
  const [obfuscate, setobfuscate ] = useState(false) ;
  const [minimum_time, setminimum_time ] = useState('') ;
  const [number_of_spots, setnumber_of_spots ] = useState('') ;
  const [type, settype ] = useState('') ;
  const [unit, setunit ] = useState('') ;
  const [isBooked, setisBooked ] = useState(false) ;
  const [confirmation_required, setconfirmation_required ] = useState(false) ;
  const [confirmation_requested, setconfirmation_requested ] = useState(false) ;
  const [confirmation_given, setconfirmation_given ] = useState(false) ;
  const [confirmation_declined, setconfirmation_declined ] = useState(false) ;
  const [EV_charger, setEV_charger ] = useState(false) ;
  const [expressCode, setExpressCode] = useState(null);
  const [expressDuration, setExpressDuration] = useState(null);
  const [qrHidden, setQrHidden] = useState(true)


    useEffect(() => {
        getSpots();
        getCurrentProfile();
    }, [getSpots, getCurrentProfile]);

    return loading ? <Spinner /> : <Fragment>
      

      <h1>Parkplatz mieten</h1>
        <div className="spot-form">
        
        <form className="form my-1" onSubmit={async (e) => {
          e.preventDefault();
          const from = Date.parse(from_date + 'T' + from_time + '+0100');
          const to = Date.parse(to_date + 'T' + to_time + '+0100');
          console.log(from, to);
          getSpotsFiltered({from, to});
          setFromReservation(from);
          setToReservation(to);
        }}>

          <Segment color='red'>
            <h2>Express-Miete</h2>
            <Table style={{border: 0}} stackable padded><Table.Row><Table.Cell width='2' verticalAlign='top'>
            <Input placeholder='Parkplatz-Code' type="text" id="expressCode" value={expressCode} onChange={event => {setExpressCode(event.target.value); console.log(expressCode)}} name="expressCode" /><br />
            <Input placeholder='Dauer' type="number" id="expressDuration" value={expressDuration} onChange={event => {setExpressDuration(event.target.value); console.log(expressDuration)}} name="expressDuration" /><br />
            </Table.Cell>
            <Table.Cell width='8' verticalAlign='top'>
            <Button secondary onClick={() => {setQrHidden(false)}}>Code scannen</Button><br />
              {!qrHidden && <QR setExpressCode={setExpressCode} setQrHidden={setQrHidden}/>}
              
            <Button primary>Parkplatz ab sofort {expressDuration ? ('für ' + expressDuration + ' Stunden' ) : (null)} buchen</Button>
            </Table.Cell></Table.Row></Table></Segment>
          
        <MapComponentMieten spots={spots} from_reservation={from_reservation} to_reservation={to_reservation} key={from_date} tenant={"5f5e7c35d677003752bfa8d3"} />

<Segment color='blue'>
  <h2>Gewünschte Parkdauer</h2>
<Table stackable padded>
<Table.Body>
        <Table.Row>
        <Table.Cell>
            <Label pointing='right'>Von:</Label>
            <input className="ui date" type="date" id="from_date" value={from_date} onChange={event => {setfrom_date(event.target.value); setto_date(event.target.value); console.log(from_date)}} name="from_date" />
            <input className="ui time" type="time" id="from_time" value={from_time} onChange={event => {setfrom_time(event.target.value); setto_time(event.target.value); console.log(from_time)}} name="from_time" /> <br />
          </Table.Cell>
          <Table.Cell>
            <Label pointing='right'>Bis:</Label>
            <input className="ui date" type="date" id="to_date" value={(to_date <= from_date) ? from_date : to_date} onChange={event => setto_date(event.target.value)} name="to_date" />
            <input className="ui time" type="time" id="to_time" value={((to_date === from_date) && (to_time < from_time)) ? from_time : to_time} onChange={event => setto_time(event.target.value)} name="to_time" /> <br />
          </Table.Cell>
        </Table.Row>

</Table.Body>

</Table>
</Segment>

<input type="submit" className="btn btn-dark my-1" value="Parkplätze filtern" />
        

</form>
      </div>
    
    </Fragment>
}

Mieten.propTypes = {

    getSpots: PropTypes.func.isRequired,
    spot: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    spot: state.spot,   
    auth: state.auth,
    profile: state.profile,
})

export default connect(mapStateToProps, {getCurrentProfile, getSpots, getSpotsFiltered, setToReservation, setFromReservation, makeReservation})(Mieten);


/*

const yesno = [
    {
      key: 'Ja',
      text: "Ja",
      value: true
    },
    {
      key: 'Nein',
      text: "Nein",
      value: false
    }
  ];

  const zeitraum_options = [
    { key: 'monat', text: "Monat", value: "monat"},
    { key: 'stunde', text: "Stunde", value: "stunde"},
    { key: 'tag', text: "Tag", value: "tag"}
  ];

  const frequency_options = [
    { key: 'täglich', text: 'täglich', value: 'täglich'},
    { key: 'wöchentlich', text: 'wöchentlich', value: 'wöchentlich'},
    { key: 'monatlich', text: 'monatlich', value: 'monatlich'}
  ];

  const type_options = [
    { key: 'normal', text: "Standard", value: "monat"},
    { key: 'motorcycle', text: "Motorrad", value: "stunde"},
    { key: 'extra-large', text: "Extra-gross", value: "tag"}
  ];

  const access_options = [
    { key: 'free_access', text: "Freier Zugang", value: "free_access"},
    { key: 'restricted_access', text: "Kein freier Zugang", value: "restricted_access"}
  ];

    const mapStyles = {
        width: '80%',
        height: '90%'
      };


      
*/