import React, {Fragment, useEffect, useState} from 'react';
import {Button, Header, Card, Image, Icon, Table, Input} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {deleteSpot, deleteReservation} from '../../actions/spot';
import {getCurrentProfile, deleteAccount} from '../../actions/profile';
import Guthaben from './Guthaben';


const MyProfile = ({getCurrentProfile, auth: {user}, profile: {profile, loading}}) => {

  const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);
  const [fileURL, setFileURL] = useState('https://react.semantic-ui.com/images/avatar/large/matthew.png');

  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
    setFileURL(URL.createObjectURL(event.target.files[0]))
	};


  const handleSubmission = () => {
		console.log('submit')
    console.log(fileURL);
	};

return (<Fragment>
  <h1>Mein Profil</h1>
  <Table stackable celled>
  <Table.HeaderCell colSpan='2'>Persönliche Daten</Table.HeaderCell>
  <Table.Row>
    <Table.Cell>
    Name:</Table.Cell>
    <Table.Cell>
    {user && user.name}</Table.Cell>
  </Table.Row>
  <Table.Row>
    <Table.Cell>
    E-Mail-Adresse:</Table.Cell>
    <Table.Cell>
    {user && user.email}</Table.Cell>
  </Table.Row>
  <Table.Row>
    <Table.Cell>
      Foto 
    </Table.Cell>
    <Table.Cell><img style={{width: '10em'}} src={fileURL} /><br />
      <input type="file" id="file" accept="image/*" onChange={changeHandler} /><br/> <br/>
      <Button onClick={handleSubmission}>Speichern</Button>
    </Table.Cell>
  </Table.Row>

  <Table.Row>
 
  </Table.Row>
  </Table>

    
           
    
<Guthaben /><Button onClick={() => deleteAccount()} className="btn btn-danger">
                            <i className="fas fa-user-minus"> 
                            </i> Account löschen
            </Button></Fragment>
  )}


  const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
  })
  
  
  export default connect(mapStateToProps, {getCurrentProfile, deleteAccount})(MyProfile)
  