import React from 'react'
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { globalVars } from './Texts';
import { Table } from 'semantic-ui-react';


const Landing = ({isAuthenticated}) => {
  const language = 'DE'
    if(isAuthenticated) {
      return <Redirect to='/dashboard' />
    }

    return (<>
      <section className='landing'>
      <div className='dark-overlay'>
        <div className='landing-inner'>
          <h1 className='x-large'>{globalVars[language].productName}</h1>
          <p className='lead'>
            Mieten oder vermieten Sie stundenweise Parkplätze!
          </p>
          <div className='buttons'>
            <Link to='/register' className='btn btn-primary'>
              Registrieren
            </Link>
            <Link to='/login' className='btn btn-light'>
              Anmelden
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className='landingVermieten'>
    <div className='landing-innerVermieten'>
      <table cellPadding='20'><tr><td colSpan='2'><h1 className='large'>Sie haben einen Parkplatz, den Sie z.B. tagsüber nicht selber benötigen?</h1></td></tr>
        <tr><td width={'50%'}>
    <p className='lead'>Verdienen Sie Geld, indem Sie den Parkplatz stundenweise vermieten.</p></td>
    <td><img src='pic1.jpg' /></td>
    
    </tr></table></div>
  </section>
  <section className='landingMieten'>
  <div className='landing-innerMieten'>
  <table style={{padding: '1rem'}}><tr><td colSpan='2'>
    <h1 className='large'>Schluss mit der lästigen Parkplatzsuche!</h1></td></tr>
    <tr><td width={'50%'}>
    <p className='lead'>Reservieren Sie bequem einen Parkplatz – jederzeit und in nur wenigen Klicks. </p></td>
    <td><img src='pic2.jpg' /></td>
    </tr>
    </table></div>
  </section>
  </>
  
  
    )
}

Landing.propTypes = {
  isAuthenticated: PropTypes.bool
}


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Landing);