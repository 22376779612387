import React, {Fragment, useEffect} from 'react';
import {Button, Header, Card} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import {deleteSpot, deleteReservation} from '../../actions/spot';

const MyMieten = ({reservations, deleteSpot, deleteReservation}) => {


    console.log(reservations);

    const reservations_in_spot = (reservation) => {
        console.log("reservation", reservation)
        return reservation.map(reservation_in_spot => 
            ( 
        <tr key={reservation_in_spot._id} style={reservation_in_spot.archived ? {textDecoration:'line-through'} : {display:''}}>
            <td>Von:<br />
                {reservation_in_spot.from} <br />
            Bis: <br />
            {reservation_in_spot.to}</td>
            <td><Button disabled={reservation_in_spot.archived} onClick={() => {console.log(reservation_in_spot._id); deleteReservation(reservation_in_spot._id)}}><i className='fas fa-ban' /></Button></td>
        </tr>
    ))
    }


    const myReservations = reservations.map(reservation => (
<Card><Card.Content>
            <Card.Header>{reservation.comment}</Card.Header>
          <Card.Meta>{reservation.spot_id}</Card.Meta>
          <Card.Description>
            <h3>Reservierungen:</h3>
    {reservations_in_spot(reservation.my_reservations_in_spot)}
          </Card.Description>
            </Card.Content></Card>

    ));
    return (
        <Fragment>
                    <h2 className="my-2">Meine Reservierungen</h2> 
            <Card.Group>{myReservations}</Card.Group>
        </Fragment>
    )
}

export default connect(null, {deleteSpot, deleteReservation})(MyMieten);