import React from "react";
import { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Button, Card, Image, Statistic, Table } from "semantic-ui-react";
import PayPalButton from './PayPalButton';
import {Input} from 'semantic-ui-react';
import Datatrans from "./Datatrans";
import {init_datatrans, get_balance} from '../../actions/payments';
import {connect} from 'react-redux';
import Lightbox from 'react-datatrans-light-box'
import { Link, Redirect } from "react-router-dom";
import payments from "../../reducers/payments";

 

function Guthaben ({init_datatrans, get_balance, payments: {transactionId, balanceInCents}, auth: {user}}) {

    const [amount, setAmount] = useState(20);


    function onChange({ target: { value } }) {
        const oldamount = amount
        setAmount(value);
        if (oldamount !== value) {init_datatrans(amount)};
    }

    useEffect(() => {
        !transactionId && init_datatrans(amount);
        get_balance();
    }, [payments]);


  return (<div>
    <Card >
    <Card.Content>
      <Card.Header>Mein Guthaben</Card.Header>
      <Card.Meta>
      </Card.Meta>
      <Card.Description>
        <Statistic>
                <Statistic.Label>CHF</Statistic.Label>
                <Statistic.Value>{payments ? (parseInt(balanceInCents) / 100) : 'x'}
                </Statistic.Value>
            </Statistic>
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
        <Input placeholder="Betrag" className="ui text" type="number" id="amount" name="amount" value={amount} onChange={
                    onChange
                    }/>
                    <Link to={`/datatrans/${amount}/${transactionId}`}
                    >
        <Button onClick={() => { 
            window.Datatrans.startPayment({
                transactionId:  transactionId
                })}}>Guthaben aufladen</Button></Link>
    </Card.Content>
  </Card>

    

  </div>)
}

const mapStateToProps = state => ({
    payments: state.payments,
    auth: state.auth
})

export default connect(mapStateToProps, {init_datatrans, get_balance})(Guthaben);