import React, {useState} from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import {  Table } from "semantic-ui-react";
import Wochentag from "./RecurringWochentagMieten";


const Recurring = params => {



    return (
    <Fragment>
        <p><br />Der Parkplatz ist wöchentlich an den folgenden Tagen und Uhrzeiten verfügbar:</p>
        <Table stackable compact fixed>
            <Table.Row>
                <Wochentag day='Montag' spot={params.spot} /> 
                <Wochentag day='Dienstag' spot={params.spot}/> 
                <Wochentag day='Mittwoch' spot={params.spot}/> 
                <Wochentag day='Donnerstag' spot={params.spot}/> 
                <Wochentag day='Freitag' spot={params.spot}/> 
                <Wochentag day='Samstag' spot={params.spot}/> 
                <Wochentag day='Sonntag' spot={params.spot}/>
            </Table.Row>
            
        </Table>
      
    </Fragment>
    )
}

export default Recurring;