import React, {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {logout} from '../../actions/auth';
import {globalVars} from './Texts.js';
import { Dropdown } from 'semantic-ui-react';

const Navbar = ({auth: {isAuthenticated, loading}, logout}) => {
  
  const [language, setLanguage] = useState('DE');
  const {productName} = globalVars[language];


  const languageOptions = [
    {
      key: 'DE',
      text: "DE",
      value: 'DE'
    },
    {
      key: 'EN',
      text: "More languages coming soon!",
      value: 'EN'
    }
  ];

  const LanguageSelector = (
    <Dropdown
        placeholder={language}
        inline
        options={languageOptions}
        direction='left'
  />
  )


  ;

  const authLinks = (
    <ul>
        <li><Link to="/reservierungen">Übersicht</Link></li> 
        <li><Link to="/vermieten/new">Vermieten</Link></li>     
        <li><Link to="/mieten">Mieten</Link></li> 
        <li><Link to="/myprofile"><i className='fas fa-user' />{' '}<span className='hide-sm'>Mein Profil</span></Link></li>
        <li>
          <a onClick={logout} href="#!">
          <i className='fas fa-sign-out-alt' />{' '}
        <span className='hide-sm'>Logout</span>
        </a>
        </li>
        <li>{LanguageSelector}</li>
      </ul>
  );
  
  const guestLinks = (
    <ul>
        <li><Link to="/register">Registrieren</Link></li>
        <li><Link to="/login">Anmelden</Link></li>
        <li>{LanguageSelector}</li>
      </ul>
  );
  
  return (
        <nav className="navbar bg-dark navbarGrid">
      <h1>
        <Link to='/reservierungen'><i className="fas fa-parking"></i> {productName}</Link>
      </h1>
       { !loading && <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>}

      
    </nav>
    );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {logout})(Navbar);

//       { !loading && <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>}
//{ <Fragment>{guestLinks}</Fragment>}
