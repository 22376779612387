import React, {useState} from "react";
import { Fragment } from "react";
import QrScan from 'react-qr-reader'
import { Button } from "semantic-ui-react";


const QR = params => {


  const handleScan = data => {
    if (data) {
      params.setExpressCode(data);
    }
  }


  const handleError = err => {
    console.error(err)
  }


    return (<Fragment>
    <QrScan
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '60%' }}
              /> 
              <Button primary onClick={() => {params.setQrHidden(true)}}>Schliessen</Button><br /></Fragment>
    )
}

export default QR;