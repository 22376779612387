import moment from "moment";
import React, {useEffect, useState} from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import { Button, Checkbox, Container, Divider, Label, Segment, Table } from "semantic-ui-react";


const Wochentag = params => {
const [from_time, setfrom_time] = useState('08:00')
const [to_time, setto_time] = useState('17:00')
const [dayselected, setdayselected] = useState(false);
const [wholeday, setwholeday] = useState(false);

const day_lowercase = params.day.toLowerCase();
useEffect(() => {
    const recurringweekly_from_parent_component = params.recurringweekly;
    recurringweekly_from_parent_component[day_lowercase].available = dayselected;
    recurringweekly_from_parent_component[day_lowercase].from_time = from_time;
    recurringweekly_from_parent_component[day_lowercase].to_time = to_time;
    recurringweekly_from_parent_component[day_lowercase].wholeday = wholeday;
    params.setrecurringweekly(recurringweekly_from_parent_component);
    console.log(params.recurringweekly);
}, [from_time, to_time, dayselected, wholeday])

    return (<Table.Cell width='1' verticalAlign='top' textAlign='center'>
        
                        <Button toggle active={dayselected} onClick={() => setdayselected(!dayselected)}>{params.day.slice(0,2)}</Button>
                         <Segment hidden={!dayselected}>
                        <p> </p><Checkbox size='small' toggle checked={wholeday} onChange={event => {setwholeday(!wholeday)}} /><br /><p style={{fontSize: '10px'}}>ganztags</p>
                        <p  style={{fontSize: '10px'}}></p>
                        
                            <p> </p>
                            <Table hidden={wholeday} stackable compact style={{border: 0}}>
                                <Table.Row >
                                <Label pointing='right'>Von:</Label>
                                    <input className="ui time" type="time" id="from_time" value={from_time} onChange={event => {
                                            setfrom_time(event.target.value); 
                                            setto_time(event.target.value);
                                        }
                                        } name="from_time" /> <br />
                                </Table.Row>
                                <Table.Row>
                                    <br />
                                <Label pointing='right'>Bis:</Label>
                                    <input className="ui time" type="time" id="to_time" value={to_time} onChange={event => {
                                            const toParsed = Date.parse('2021-01-01' + ' ' + event.target.value);
                                            const fromParsed = Date.parse('2021-01-01' + ' ' + from_time);
                                            moment(toParsed).isAfter(fromParsed) ? 
                                            setto_time(event.target.value) : setto_time(from_time) 
                                        }
                                        } name="to_time" /> <br />
                                </Table.Row>
                            </Table>
                           

                        </Segment>
                        </Table.Cell>
    )
}

export default Wochentag;