export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const GET_SPOTS_FILTERED = 'GET_SPOTS_FILTERED';
export const GET_SPOT = 'GET_SPOT';
export const GET_SPOTS = 'GET_SPOTS';
export const SPOT_ERROR = 'SPOT_ERROR';
export const ADD_SPOT = 'ADD_SPOT';
export const DELETE_SPOT = 'DELETE_SPOT';
export const LAT_LNG = 'LAT_LNG';
export const GET_MY_VERMIETEN = 'GET_MY_VERMIETEN';
export const GET_RESERVATIONS = 'GET_RESERVATIONS';
export const SETLAT = 'SETLAT';
export const SETLNG = 'SETLNG';
export const MAKE_RESERVATION = 'MAKE_RESERVATION';
export const RESERVATION_ERROR = 'RESERVATION_ERROR';
export const SET_TO_RESERVATION = 'SET_TO_RESERVATION';
export const SET_FROM_RESERVATION = 'SET_FROM_RESERVATION';
export const SET_FROM_RESERVATION_ERROR = 'SET_FROM_RESERVATION_ERROR';
export const SET_TO_RESERVATION_ERROR = 'SET_TO_RESERVATION_ERROR';
export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_RESERVATION = 'DELETE_RESERVATION';
export const INIT_DATATRANS = "INIT_DATATRANS";
export const INIT_DATATRANS_ERROR = "INIT_DATATRANS_ERROR";
export const CHECK_STATUS_DATATRANS = "CHECK_STATUS_DATATRANS";
export const CHECK_STATUS_DATATRANS_ERROR = "CHECK_STATUS_DATATRANS_ERROR";
export const BALANCE_DATATRANS = 'BALANCE_DATATRANS';
export const BALANCE_DATATRANS_ERROR = 'BALANCE_DATATRANS_ERROR';