import React, {useState, useRef, useEffect, Component, Fragment} from 'react';
import {getSpots, setLat, setLng, setToReservation} from '../../actions/spot';
import {connect} from 'react-redux';
import spot from '../../reducers/spot';

import { GoogleMap, LoadScript, Marker, InfoWindow, StandaloneSearchBox, Autocomplete, useJsApiLoader } from '@react-google-maps/api';

 
/*global google */

const containerStyle = {
  width: '100%',
  height: '400px'
};
 

const MapComponent = ({setLat, setLng, spot}) => {

const [selectedPlace, setselectedPlace] = useState({});
const [map, setMap] = React.useState(null)
const [showingInfoWindow, setshowingInfoWindow] = useState(false);
const [activeMarker, setactiveMarker] = useState({});
const [lat_place, setLat_place] = useState(47.36905191105592);
const [lng_place, setLng_place] = useState(8.539005558524545);
const [center, setCenter] = useState({
    lat: lat_place,
    lng: lng_place
  });
const [zoomlevel, setZoomlevel] = useState(18);
const [mapTypeId, setMapTypeId] = useState();


useEffect( () => {
})
  
const centerMap = () => {
  if (!!navigator.geolocation) {
    navigator.geolocation.watchPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => console.log(err),
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 },
    );
  } else {
    //  // No Support Web
    alert('The browser does not support geolocalization,')
  }
}

  const onMarkerClick = (props, marker, e) => {
    console.log(onMarkerClick);
    console.log(marker);
    setselectedPlace(props);
    setactiveMarker(marker);
    setshowingInfoWindow(true)
    console.log("name", props, marker);
    setMapTypeId('satellite');
  }

 const onMapClicked = (e) => {
  console.log("onMapClicked", e.latLng.lat())
  setLat(e.latLng.lat());
  setLng(e.latLng.lng())
  console.log(spot.lat)

  if (showingInfoWindow) {
    setshowingInfoWindow(false);
    setactiveMarker(null);
  }
 }


const onLoad = React.useCallback(function callback(map) {

  

map.setOptions({
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_BOTTOM
    },
    streetViewControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM
    },
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM
    }
  });


  


}, [])

const onUnmount = React.useCallback(function callback(map) {
  setMap(null)
}, [])

var autocomplete_const = null;

const onLoadAutocomplete = (autocomplete) => {
  console.log('autocomplete: ', autocomplete);
  autocomplete_const = autocomplete;
  autocomplete_const.addListener('place_changed', onPlaceChangedAutocomplete)

}

const onPlaceChangedAutocomplete = async () => {
  if (autocomplete_const !== null) {
    const places = await autocomplete_const.getPlace().geometry.location
    let lat_place = parseFloat(places.lat());
    let lng_place = parseFloat(places.lng());
    setCenter({lat: lat_place, lng: lng_place});
    setLat(lat_place);
    setLng(lng_place);
    setZoomlevel(20);
    setshowingInfoWindow(false);
    setactiveMarker(null);
  } else {
    console.log('Autocomplete is not loaded yet!')
  }
}

const libraries=['places'];

const {isLoaded, loadError} = useJsApiLoader({
  googleMapsApiKey: 'AIzaSyBHvHsNPKLxE5CQoiPgCiBUGLPzuqnwrI0', // process.env.REACT_APP_MAP_API,
  libraries,
})

  return (
    isLoaded ? <Fragment>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}      
        zoom={zoomlevel}
        
        onLoad={onLoad}

        onUnmount={onUnmount}
        onClick={(e) => onMapClicked(e)}
        mapTypeId = {mapTypeId}
      >
        { spot.lat !== 'lat' ?
        <Marker onClick={onMarkerClick} position={{ lat: spot.lat, lng: spot.lng }} >
          <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}>
            <div>
              <h1>Mein Parkplatz</h1>
            </div>
        </InfoWindow></Marker> : <></>}
        <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChangedAutocomplete}
          ><input
          type="text"
          placeholder="Bitte geben Sie die Adresse Ihres Parkplatzes ein"
          autocomplete="on"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `80%`,
            height: `48px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "10%",
            top: "1%",
            marginLeft: "-0px"
          }}
        /></Autocomplete>
                
      </GoogleMap><button className = 'btn btn-light' onClick={() => {centerMap()}}>Aktuelle Position</button></Fragment> : <></>
    
  )}


export default connect(null, {setLat, setLng})(MapComponent);

/*


const [lat, setlat] = useState({});
const [lng, setlng] = useState({});
const [input, setInput] = useState('');

const [test, setTest] = useState(0);


<LoadScript
    id="script-loader"
      googleMapsApiKey="AIzaSyBHvHsNPKLxE5CQoiPgCiBUGLPzuqnwrI0"
      libraries = {libraries}
    ></LoadScript>


*/


  /*
  const bounds = new window.google.maps.LatLngBounds();
  map.fitBounds(bounds);
  setMap(map); */
