import React, {useState} from "react";
import { Fragment } from "react";
import ReactDOM from "react-dom";
import { Button, Container, Label, Segment, Table } from "semantic-ui-react";
import Wochentag from "./Recurring_Wochentag";


const Recurring = params => {


    const [from_time_montag, setfrom_time_montag] = useState('00:00');
    const [to_time_montag, setto_time_montag] = useState('00:00');

    const [dienstag, setdienstag] = useState(false);
    const [mittwoch, setmittwoch] = useState(false);
    const [donnerstag, setdonnerstag] = useState(false);
    const [freitag, setfreitag] = useState(false);
    const [samstag, setsamstag] = useState(false);
    const [sonntag, setsonntag] = useState(false);

    return (
    <Fragment>
        <p><br />Der Parkplatz ist wöchentlich an den folgenden Tagen und Uhrzeiten verfügbar:</p>
        <Table stackable compact fixed>
            <Table.Row>
                
                <Wochentag day='Montag' setrecurringweekly={params.setrecurringweekly} recurringweekly={params.recurringweekly} /> 
                <Wochentag day='Dienstag'  setrecurringweekly={params.setrecurringweekly} recurringweekly={params.recurringweekly}/> 
                <Wochentag day='Mittwoch'  setrecurringweekly={params.setrecurringweekly} recurringweekly={params.recurringweekly}/> 
                <Wochentag day='Donnerstag'  setrecurringweekly={params.setrecurringweekly} recurringweekly={params.recurringweekly}/> 
                <Wochentag day='Freitag'  setrecurringweekly={params.setrecurringweekly} recurringweekly={params.recurringweekly}/> 
                <Wochentag day='Samstag'  setrecurringweekly={params.setrecurringweekly} recurringweekly={params.recurringweekly}/> 
                <Wochentag day='Sonntag'  setrecurringweekly={params.setrecurringweekly} recurringweekly={params.recurringweekly}/>
            </Table.Row>
            
        </Table>
      
    </Fragment>
    )
}

export default Recurring;