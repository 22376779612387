import React, {Fragment, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Landing from './components/layout/Landing';
import Routes from './components/routing/Routes';

//Redux
import {Provider} from 'react-redux';
import store from './store';



import {loadUser} from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import 'semantic-ui-css/semantic.min.css'

if(localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser);
  }, []);

  return (
  <Provider store={store}>
  <Router>
    <Fragment>
      <div className='custom-font'>
      <Navbar />
      <Switch>
              <Route exact path="/" component={Landing} />
              <Route component={Routes} />
      </Switch>
      <Footer /></div>
    </Fragment></Router>
    
    


    </Provider>
  )};

export default App;
