import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {logout} from '../../actions/auth';
import { Container, Divider, Grid, Header, Icon, Image, List, Segment } from 'semantic-ui-react';
import {globalVars, footerText, socialMedia} from './Texts';

const Footer = () => {
    const language = 'DE'
    const {contact, terms, cta, privacy, followUs, getTheApp, comingSoon} = footerText[language]
    const {productName} = globalVars[language]
    const {facebook, instagram, twitter, youtube} = socialMedia 


  return (
    <div id="footer">
    <Segment  inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}> 
    <Container textAlign='center'>
      <Grid divided inverted stackable>
        <Grid.Column width={5}>
          <Header inverted as='h4' content={productName + ' ' + followUs} />
          <Link to={{ pathname: facebook }} target="_blank" rel="noopener noreferrer"><Icon inverted size='large' name='facebook official' /></Link>
          <Link to={{ pathname: instagram }} target="_blank" rel="noopener noreferrer"><Icon inverted size='large' name='instagram official' /></Link>
          <Link to={{ pathname: twitter }} target="_blank" rel="noopener noreferrer"><Icon inverted size='large' name='twitter official' /></Link>
          <Link to={{ pathname: youtube }} target="_blank" rel="noopener noreferrer"><Icon inverted size='large' name='youtube official' /></Link>
          
        </Grid.Column>
        <Grid.Column width={5}>
          <Header inverted as='h4' content={getTheApp} />
          <List link inverted>
            <List.Item>{comingSoon}</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header inverted as='h4' content={productName} />
          <p>
          {cta}
          </p>
        </Grid.Column>
      </Grid>

      <Divider inverted section />
      <i className="fas fa-parking"></i><br />
      <List horizontal inverted divided link size='small'>
        <List.Item as='a' href='#'>
        {contact}
        </List.Item>
        <List.Item as='a' href='#'>
        {terms}
        </List.Item>
        <List.Item as='a' href='#'>
          {privacy}
        </List.Item>
      </List>
    </Container>
  </Segment></div>
    );
};



export default Footer;


// <Image centered size='mini' src='/logo.png' />

/*
<Grid.Column width={3}>
          <Header inverted as='h4' content='Group 1' />
          <List link inverted>
            <List.Item as='a'>Link One</List.Item>
            <List.Item as='a'>Link Two</List.Item>
            <List.Item as='a'>Link Three</List.Item>
            <List.Item as='a'>Link Four</List.Item>
          </List>
        </Grid.Column>

*/