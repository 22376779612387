export const globalVars = {DE: 
    {productName: 'ParkingSpace'}, 
    en: {productName: 'ParkingSpace'}};

export const socialMedia = {
    facebook: 'http://www.facebook.com', 
    instagram: 'http://www.instagram.com', 
    twitter: 'http://www.instagram.com', 
    youtube: 'http://www.youtube.com'
}

export const footerText = {DE: 
    {privacy: 'Datenschutzerlärung',
    terms: 'AGB',
    cta: 'Die Plattform für Kurzparkplätze - nie mehr Parkplatz suchen!',
    contact: 'Kontakt',
    followUs: 'auf',
    getTheApp: 'App herunterladen',
    comingSoon: 'Bald!'
}, 
    
    en: 
    {privacy: 'Privacy policy',
    terms: 'Terms and conditions',
    cta: 'The platform for short-term parking - no more searching for a spot!',
    contact: 'Contact us',
    followUs: 'on',
    getTheApp: 'Get the app',
    comingSoon: 'Soon!'
}
}