import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';
import spot from './spot';
import config from './config';
import payments from './payments';

export default combineReducers({
    config,
    alert,
    auth,
    profile,
    post,
    spot,
    payments
});