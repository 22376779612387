import React, {useEffect, Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DashboardActions from './ReservierungenActions';
//import Experience from './ReservierungenVermieten';
//import Education from './ReservierungenMieten';
import {getCurrentProfile, deleteAccount} from '../../actions/profile';
import Spinner from '../layout/Spinner';
import MyVermieten from './ReservierungenVermieten';
import MyMieten from './ReservierungenMieten';
import PayPalButton from './PayPalButton';
import Guthaben from './Guthaben';
import MyProfile from './MyProfile';
import { Segment } from 'semantic-ui-react';

const Dashboard = ({getCurrentProfile, deleteAccount, auth: {user}, profile: {profile, loading}}) => {
    useEffect(()=> {
        getCurrentProfile();
    }, [getCurrentProfile]);

    return loading && profile === null ? <Spinner /> : <Fragment>
    <h1 className="large text-primary">Mein Dashboard</h1>
    {profile !== null ? <Fragment>
        <Segment color = 'red'><h2>Mein Profil</h2>Profil bearbeiten<Guthaben /></Segment>
        <Segment color = 'orange'><MyMieten reservations={profile.my_reservations[0]}/></Segment>
        <Segment color = 'green'><MyVermieten profile={profile.my_spots[0]}/></Segment>
    </Fragment>
    :
    <Fragment>
        <p>Sie haben noch keine Parkplätze reserviert oder eigene Parkplätze erfasst.</p>
        
    </Fragment>
        }    
    </Fragment>;
}

Dashboard.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    deleteAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
})

export default connect(mapStateToProps, {getCurrentProfile, deleteAccount})(Dashboard);
