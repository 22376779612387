import React, {useState} from "react";
import ReactDOM from "react-dom"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Fragment } from "react";
import { Input } from "semantic-ui-react";


function PayPalButton() {

    const [amount, setAmount] = useState(20);
    const [orderID, setOrderID] = useState(false);

    function createOrder(data, actions) {
            return actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                value: amount,
                            },
                        },
                    ],
                })
                .then((orderID) => {
                    setOrderID(orderID);
                    return orderID;
                });
        }



    function onChange({ target: { value } }) {
        setAmount(value);
        setOrderID(false);
    }


return(
    <Fragment>
            <Input placeholder="Betrag" className="ui text" type="number" id="amount" name="amount" value={amount} onChange={
                onChange
                }/><br />

    
<p>Order ID: {orderID ? orderID : "unknown"}</p>
    <PayPalScriptProvider options={{ "client-id": "AZuKDRudNuRRRRlLXWu84x_e_unvpwc95SyhV4ew6GMbGMkZYdLNQKqlSu0_HeqYpC8vOAk0W2f9NNPy" }}>
            <PayPalButtons style={{ color: "blue", shape: "pill", label: "pay", height: 40 }} createOrder={createOrder} forceReRender={amount} />
        </PayPalScriptProvider></Fragment>
)
};

export default PayPalButton
/*

<select onChange={onChange} name="amount" id="amount">
        <option value="2">CHF 20</option>
        <option value="4">CHF 40</option>
        <option value="6">CHF 60</option>
</select>


const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function YourComponent(props) {
  const createOrder = (data, actions) =>{
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "0.02",
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture();
  };

  return (<div>
      {props.amount}
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
    /></div>
  );
}


export default YourComponent;
*/