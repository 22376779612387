import axios from 'axios';

import {setAlert} from './alert';

import {
    INIT_DATATRANS,
    INIT_DATATRANS_ERROR,
    CHECK_STATUS_DATATRANS,
    CHECK_STATUS_DATATRANS_ERROR,
    BALANCE_DATATRANS_ERROR,
    BALANCE_DATATRANS
} from './types';


// Get balance
export const get_balance = () => async dispatch => {
    try {
        const config = {
            method: 'get',
            url: '/api/datatrans/balance'
          };
                
        const res = await axios(config);

        dispatch({
            type: BALANCE_DATATRANS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: BALANCE_DATATRANS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}

// Check status
export const check_status = (transactionId) => async dispatch => {
    try {
        const config = {
            method: 'post',
            url: '/api/datatrans/payment_status',
            data: {transactionId: transactionId}
        };

        const res = await axios(config);
        dispatch({
            type: CHECK_STATUS_DATATRANS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: CHECK_STATUS_DATATRANS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
};

// Initialize Datatrans
export const init_datatrans = (amountInCents) => async dispatch => {
    try {

        const config = {
            method: 'post',
            url: '/api/datatrans/payment',
            data : {amount: amountInCents * 100}
          };
          
                  
        const res = await axios(config);



        //const res = await axios.post('/api/datatrans/payment');


        dispatch({
            type: INIT_DATATRANS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: INIT_DATATRANS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status}
        })
    }
}
